import './src/styles/main.scss'
import '@fortawesome/fontawesome-free/scss/fontawesome.scss'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

const sUsrAg = window.navigator.userAgent
let sBrowser = ``
if (sUsrAg.indexOf(`Firefox`) > -1) {
  sBrowser = `Firefox`
} else if (sUsrAg.indexOf(`SamsungBrowser`) > -1) {
  sBrowser = `SamsungInternet`
} else if (sUsrAg.indexOf(`Opera`) > -1 || sUsrAg.indexOf(`OPR`) > -1) {
  sBrowser = `Opera`
} else if (sUsrAg.indexOf(`Trident`) > -1) {
  sBrowser = `InternetExplorer`
} else if (sUsrAg.indexOf(`Edge`) > -1) {
  sBrowser = `EdgeLegacy`
} else if (sUsrAg.indexOf(`Edg`) > -1) {
  sBrowser = `Edge`
} else if (sUsrAg.indexOf(`Chrome`) > -1) {
  sBrowser = `Chrome`
} else if (sUsrAg.indexOf(`Safari`) > -1) {
  sBrowser = `Safari`
} else {
  sBrowser = `unknown`
}
document.querySelector(`html`).setAttribute(`data-browser`, sBrowser)

Bugsnag.start({
  apiKey: `0a86ce40654e64e3e9de08bdd53cff75`,
  plugins: [new BugsnagPluginReact()],
  appType: `client`,
  collectUserIp: false,
  enabledReleaseStages: [`production`],
  releaseStage: process.env.GATSBY_ENV || ``,
  appVersion: process.env.GATSBY_BITBUCKET_BUILD_NUMBER || ``,
})
